import { configValue } from 'config/appConfigUtils';

export function allowRedirect(url: string) {
  const corsOrigins = configValue('idealist', 'corsOrigins');

  if (url.startsWith('//')) {
    // //example.com
    // we'll always include the scheme, https://example.com
    return false;
  }

  if (url.startsWith('/')) {
    return true;
  }

  if (!corsOrigins) {
    return false;
  }

  const allowedDomains = corsOrigins.split(',');

  if (allowedDomains.length === 1 && allowedDomains[0] === '*') {
    return true;
  }

  // remove path and query params
  const host = url.split('/').slice(0, 3).join('/').split('?')[0];
  return allowedDomains.includes(host);
}
